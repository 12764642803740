<template lang="pug">
    .main-wrapper.confirmar-pagamento
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-2
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left'
                        @click="$router.push({ name: 'orcamentos-clinica' })")
                .p-col-10.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "orcamentos/listar-validados" }'> Orçamentos</router-link> /
                        <b> Confirmar Pagamento </b>

        Dialog.dialogValidar(header='Confirmar pagamento' :modal='true' :visible.sync='dialogValidar')
            .ta-center
                p(v-if="![3, 4].includes(orcamento.cd_orcamento_situacao.id)") Deseja confirmar pagamento do orçamento <b>{{ orcamento.id }}</b>?
                p(v-else) Deseja atualizar o orçamento <b>{{ orcamento.id }}</b>?
                .ta-center.mb-4(v-if='waitingValidar')
                    ProgressSpinner.validar-waiting-spinner(strokeWidth='6')
                Button.mb-4.p-button-success(v-else label='Confirmar' icon='jam jam-ticket' @click='validarOrcamento()')

        form(@submit.prevent='handleSubmit()')
            .ta-center.my-4(v-if='waitingForm')
                ProgressSpinner.form-waiting-spinner(strokeWidth='2')

            div(v-else)
                Panel.mb-2(header='Confirmar pagamento')
                    div(v-if='orcamento && orcamento.id')

                        ConteudoVisualizar(:orcamento='orcamento' ref='orcamentoPaciente')

                        .ta-center.mb-4(v-if='waitingImprimirGuia')
                            ProgressSpinner.imprimir-waiting-spiner(strokeWidth='6')
                        .ta-center.mb-4(v-else)
                            Button.mr-2(label='Voltar' icon='jam jam-log-out' type='button' @click='reset()')
                            Button.p-button-success.mr-2(v-if="[1, 2].includes(orcamento.cd_orcamento_situacao.id)"
                                label='Confirmar pagamento' icon='jam jam-ticket' type='button' @click='dialogValidar = true')
                            Button.mr-2(v-else label="Imprimir guia" icon='jam jam-printer' type='button' @click="imprimirGuia")
                            Button.mr-2(label='Atualizar observações' v-if="[3, 4].includes(orcamento.cd_orcamento_situacao.id)"
                                icon='jam jam-save' type='button' @click='atualizar()')


                    .p-grid.p-fluid.p-justify-center(v-else)
                        .p-col-12.p-md-3.p-lg-2
                            .p-grid.p-fluid.p-align-end

                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.id.$error }")
                                    label.form-label Orçamento:
                                    InputText(type='text' v-model='$v.model.id.$model')
                                    .feedback--errors(v-if='submitted && $v.model.id.$error')
                                        .form-message--error(v-if="!$v.model.id.required") <b>Codigo do orçamento</b> é obrigatório.
                                        .form-message--error(v-if="!$v.model.id.numeric") <b>Codigo do orçamento</b> precisa ser numérico.

                                .p-col-12
                                    Button(label='Buscar')

</template>

<style lang="scss">
    .confirmar-pagamento {
        .form-waiting-spinner {
            width: 60px;
            height: auto;
        }
        .dialogValidar {
            width: 96%;
            max-width: 400px;
            padding-inline: 2%;
        }
        .validar-waiting-spinner {
            width: 30px;
            height: auto;
        }
        .imprimir-waiting-spiner {
            width: 30px;
            height: auto;
        }
    }
</style>

<script>
    import Button from 'primevue/button'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Panel from 'primevue/panel'
    import ProgressSpinner from 'primevue/progressspinner'

    import { required, numeric } from 'vuelidate/lib/validators'
    import moment from "moment"
    import {Caixas, Orcamento} from '../../middleware'
    import ConteudoVisualizar from "@/components/Orcamentos/ConteudoVisualizar";

    export default {
        components: { ConteudoVisualizar, Button, Dialog, InputText, Panel, ProgressSpinner },
        data() {
            return {
                waitingForm: false,
                waitingImprimirGuia: false,
                submitted: false,
                dialogValidar: false,
                waitingValidar: false,
                model: { id: null },
                orcamento: {
                    procedimentos: [],
                    cd_orcamento_situacao: {}
                }
            }
        },
        validations() {
            let v = {
                model: {
                    id: { required, numeric }
                }
            }
            return v
        },
        methods: {
            reset () {
                this.model.id = null
                this.orcamento = { procedimentos: [], cd_orcamento_situacao: {} }
                this.submitted = false
            },
            atualizar () {
                this.waitingValidar = true
                this.dialogValidar = true
                this.validarOrcamento()
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return

                this.waitingForm = true
                Orcamento.findToValidate(this.model.id).then(response => {
                    this.waitingForm = false
                    if ([200, 201, 204].includes(response.status)) {

                        this.orcamento = response.data
                        this.orcamento.dt_estimada_f = moment(response.data.dt_estimada).format("DD/MM/YYYY")
                        this.orcamento.dt_criado_f = moment(response.data.dt_criado).format('DD-MM-YYYY')
                        if (response.data.dt_atualizado) {
                            this.orcamento.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD-MM-YYYY')
                        }
                        this.orcamento.procedimentos = response.data.cd_orcamento_cirurgia_procedimento_estabelecimento

                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            validarOrcamento () {
                this.waitingValidar = true

                Orcamento.validar({
                                "id": this.orcamento.id,
                                "ds_observacao": this.$refs.orcamentoPaciente.orcamento.ds_observacao
                            }).then(response => {
                    this.waitingValidar = false
                    if ([200, 201, 204].includes(response.status)) {

                        this.orcamento = response.data
                        this.orcamento.ds_observacao = this.$refs.orcamentoPaciente.orcamento.ds_observacao
                        this.orcamento.dt_estimada_f = moment(response.data.dt_estimada).format("DD/MM/YYYY")
                        this.orcamento.dt_criado_f = moment(response.data.dt_criado).format('DD-MM-YYYY')
                        if (response.data.dt_atualizado) {
                            this.orcamento.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD-MM-YYYY')
                        }
                        this.orcamento.procedimentos = response.data.cd_orcamento_cirurgia_procedimento_estabelecimento
                        this.$toast.success('Orçamento validado com sucesso!')

                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                    this.dialogValidar = false
                })

            },
            imprimirGuia() {
                this.waitingImprimirGuia = true
                Caixas.imprimirGuiasLote(this.orcamento.cd_transacao_financeira).then(response => {
                    this.waitingImprimirGuia = false
                    if (response.status === 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            let fileURL = URL.createObjectURL(blob)
                            let a = window.open(fileURL)
                            a.print()
                        }
                    }
                })
            }
        }
    }
</script>
