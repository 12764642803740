<template lang="pug">
    div.visualizar-orcamento
        Dialog.dialog-opcoes-impressao(header="Opções de impressão" :modal="true" :visible.sync="dialogOpcoesImpressao.visible")
            .ta-center.my-2
                ProgressSpinner.waitingImprimir(v-if='dialogOpcoesImpressao.waiting' strokeWidth="6")
                div(v-else)
                    Button.p-button-info.mr-2(label='Impressão térmica' icon='jam jam-ticket' @click='imprimirGuias(true)')
                    Button.p-button-success(label='Impressão A4' icon='jam jam-printer' @click='imprimirGuias()')

        ConfirmDialog(:display="dialogRemoverPagamento.visible" @confirmed="removeFormadePagamento(dialogRemoverPagamento.idx, dialogRemoverPagamento.elem)" @close="dialogRemoverPagamento.visible = false")
            template(#text-content="props")
                span Deseja mesmo <b>remover</b> essa forma de pagamento?
        
        Dialog.dialogPagamento.ta-center(:modal='true' :visible.sync='waitingPagamentoAprovado' :showHeader='false' :closable='false' )
            div(style="height: 200px; display: flex; flex-direction: column; justify-content: center, align-items: center ")
                h3 Aguarde...
                ProgressSpinner(strokeWidth='6' :style="{'height':'6em'}")
        Dialog.dialogPagamento.ta-center(:modal='true' :visible.sync='waitingCancelamento' :showHeader='false' :closable='false' )
            div(style="height: 200px; display: flex; flex-direction: column; justify-content: center, align-items: center ")
                h3 Aguarde...
                ProgressSpinner(strokeWidth='6' :style="{'height':'6em'}")


        Dialog.dialogPagamento(header='Adicionar forma de pagamento' :modal='true' :visible.sync='dialogPagamento' @hide='() => { this.model.valor_pago = 0; this.model.cd_forma_pagamento = null; this.model.nr_credito_parcelas = null; onHide()}')
            .p-grid.p-fluid
                .p-col-6
                    label.form-label Método:
                    Dropdown(
                        v-model='model.cd_forma_pagamento'
                        :options='options.formasPagamento'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        @change='valor_a_adicionar = 0'
                    )
                .p-col-6
                    label.form-label Valor a pagar:
                    CurrencyInput(
                        v-model='valor_a_adicionar'
                        locale='pt-BR'
                        :auto-decimal-mode='true'
                        :value-range='{ min: 0 }')

                .p-col-6(v-if='model.cd_forma_pagamento == 1')
                    label.form-label Valor recebido:
                    CurrencyInput(
                        v-model='model.valor_pago'
                        locale='pt-BR'
                        :auto-decimal-mode='true'
                        :value-range='{ min: 0 }')

                .p-col-6(v-if='model.cd_forma_pagamento == 1')
                    label.form-label Troco:
                    CurrencyInput(
                        :value='troco'
                        disabled)

                .p-col-12(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6 || model.cd_forma_pagamento == 3')
                    Panel(toggleable :collapsed='!modelManual.ie_registro_manual' @toggle='manualTEF')
                        template(#header)
                            .title-header-panel
                                i.jam.jam-credit-card
                                span Inserir manualmente:

                        .p-grid
                            .p-col-6
                                label.form-label Operadora:
                                Dropdown(
                                    v-model='modelManual.cd_operadora'
                                    :options='options.operadoras'
                                    optionLabel='ds_operadora'
                                    optionValue='id'
                                    dataKey='id'
                                )
                            .p-col-6(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6')
                                label.form-label Bandeira:
                                Dropdown(
                                    :disabled='!modelManual.cd_operadora'
                                    v-model='modelManual.ie_bandeira'
                                    :options='options.bandeiras[modelManual.cd_operadora]'
                                    optionLabel='ds_valor'
                                    optionValue='ie_valor'
                                    dataKey='ie_valor'
                                )
                            .p-col-6(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6')
                                label.form-label Últimos 4 dígitos do cartão:
                                InputText(v-model='modelManual.nr_final_cartao' :useGrouping='false')

                            .p-col-6(v-if='model.cd_forma_pagamento == 5 || model.cd_forma_pagamento == 6')
                                label.form-label Código de autorização:
                                InputText(v-model='modelManual.cd_autorizacao'  :useGrouping='false')

                            .p-col-6(v-if='model.cd_forma_pagamento == 3')
                                label.form-label DOC :
                                InputText(v-model='modelManual.nr_pix_doc'  :useGrouping='false')


                .p-col-6(v-if='model.cd_forma_pagamento == 5')
                    label.form-label Parcelas:
                    Dropdown(
                        v-model='model.nr_credito_parcelas'
                        :options='ie_cobrar_cliente ? options.parcelas : options.parcelasCredishop '
                        optionLabel='text'
                        :disabled='(([0, null]).includes(model.nr_valor_adicionar)) || (modelManual.ie_bandeira == null  && modelManual.ie_registro_manual)'
                        optionValue='value'
                        dataKey='value'
                        class='parcelas'
                    )
                        template(#option='props')
                            div.p-dropdown-item
                                    span <b> {{props.option.text}}</b><br>
                                    span {{formatPrice(props.option.valor_total)}}
                                    

                .p-col-6(v-if='model.cd_forma_pagamento == 5')
                    label.form-label Valor recebido:
                    CurrencyInput.input-adicionar(
                        :value='valor_juros'
                        disabled
                        locale='pt-BR'
                        :auto-decimal-mode='false'
                        :value-range='{ min: 0 }')

                .p-col-12.ta-center
                    Button(label='Adicionar' icon='jam jam-plus' @click='adicionar()')


        Dialog.dialogFinalizar(v-if='$refs.buscarPaciente' header='Confirmação de pagamento' :modal='true' :visible.sync='dialogFinalizar')
            div(v-if='$refs.buscarPaciente.model && calcValorTotal() === totalPago()')
                h1.ta-center Resumo

                p <b>Pagante: </b> {{ $refs.buscarPaciente.model.nm_pessoa_fisica }}
                p <b>CPF: </b>{{ $refs.buscarPaciente.model.nr_cpf_f }}
                div(v-if='list.length > 1')
                    p <b>Formas de pagamento:</b>
                    ul
                        li(v-for='elem, idx in list' :key='idx')
                            p <b>{{ elem.cd_forma_pagamento_f }}: </b>{{ elem.nr_valor_f }}
                div(v-else-if='list.length === 1')
                    p <b>Forma de pagamento:</b> {{ list[0].cd_forma_pagamento_f }}
                    p <b>Valor:</b> {{ list[0].nr_valor_f }}
                div(v-else)
                    p <b>Valor:</b> R$ 0,00

                .ta-center.my-4
                    ProgressSpinner(v-if='waitingPagar' strokeWidth='6')
                    Button.p-button-success(v-else label='Confirmar' icon='jam jam-check' @click='onSubmit()')
        
        .p-grid.p-fluid.p-align-end

            .p-col-12.p-md-2
                label.form-label Código:
                InputText(readonly type='text' v-model='orcamento.id')

            .p-col-12.p-md-6(v-if='orcamento.nm_paciente')
                label.form-label Paciente:
                InputText(readonly type='text' v-model='orcamento.nm_paciente')

            .p-col-12.p-md-6(v-else)
                label.form-label Paciente:
                InputText(readonly type='text' v-model='orcamento.cd_paciente.nm_pessoa_fisica')

            .p-col-12.p-md-4
                label.form-label Data estimada:
                InputText(readonly v-model='orcamento.dt_estimada_f' dateFormat="dd/mm/yy" :locale="ptbr" :manualInput='false')

            .p-col-12
                label.form-label Observação:
                Textarea(type='text' v-model='orcamento.ds_observacao' :autoResize="true" rows="3")

            .p-col-12.p-md-6.p-lg-4
                label.form-label Descrição:
                InputText(readonly type='text' v-model='orcamento.ds_descricao')

            .p-col-12.p-md-6.p-lg-4
                label.form-label Estabelecimento:
                InputText(readonly v-model='orcamento.cd_estabelecimento.nm_fantasia')

            .p-col-12.p-lg-4(style='display:flex;align-items:center;justify-content:space-around')
                .ta-center
                    label.form-label Habilitado:
                    InputSwitch(disabled type="boolean" v-model='orcamento.ie_status')
                .ta-center
                    label.form-label Pacote:
                    InputSwitch(disabled type="boolean" v-model='orcamento.ie_pacote')
                .ta-center
                    label.form-label Conta aberta:
                    InputSwitch(disabled type="boolean" v-model='orcamento.ie_conta_aberto')
                .ta-center
                    label.form-label Recebível:
                    InputSwitch(disabled type="boolean" v-model='orcamento.ie_recebivel')

            .p-col-6.mt-3
                span <b>Usuario que criou: </b> {{ orcamento.nm_usuario_cri }}
            .p-col-6
                span <b>Data da criação: </b> {{ orcamento.dt_criado_f }}
            .p-col-6
                span <b>Usuario que editou: </b> {{ orcamento.nm_usuario_edi }}
            .p-col-6
                span <b>Data da atualização: </b> {{ orcamento.dt_atualizado_f }}

            .p-col-12.mt-2
                .p-grid.p-fluid
                    .p-col-12.mt-2
                        Panel(header='Procedimentos')
                            .p-grid.p-fluid
                                .p-col-12(v-for='(procedimento, idx) in orcamento.procedimentos' :key='idx')
                                    Card
                                        template(#content)
                                            .p-grid.p-fluid.p-align-center.card-procedimento
                                                .p-col-2.ta-right
                                                    b Descrição:
                                                .p-col-10
                                                    input.p-inputtext(readonly :value='procedimento.ds_descricao')
                                                .p-col-2.ta-right
                                                    b Qtd. procedimentos:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='procedimento.qtd_procedimento')
                                                .p-col-2.ta-right
                                                    b Hospital:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_hospital)')
                                                .p-col-2.ta-right
                                                    b Material cirúrgico:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_material_cirurgico)')
                                                .p-col-2.ta-right
                                                    b Anestesia:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_anestesista)')
                                                .p-col-2.ta-right
                                                    b OPME:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_opme)')
                                                .p-col-2.ta-right
                                                    b Exames:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_exames)')
                                                .p-col-2.ta-right
                                                    b Hemoderivados:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_hemoderivados)')
                                                .p-col-2.ta-right
                                                    b Repasse:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_repasse)')
                                                .p-col-2.ta-right
                                                    b Perfusão:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_perfusao)')
                                                .p-col-2.ta-right
                                                    b Fisioterapia:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_fisioterapeuta)')
                                                .p-col-2.ta-right
                                                    b Honorários:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_honorario)')
                                                .p-col-2.ta-right
                                                    b Taxa Medclub:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_taxa)')
                                                .p-col-2.ta-right
                                                    b Porte:
                                                .p-col-2
                                                    input.p-inputtext(readonly :value="procedimento.ie_porte === 'G' ? 'Grande/Médio' : 'Pequeno'")
                                                .p-col-2.ta-right
                                                    b Especialista:
                                                .p-col-6
                                                    input.p-inputtext(readonly :value="procedimento.cd_especialista ? procedimento.cd_especialista.nm_especialista : '(Sem especialista)'")

                                                .p-col-2.ta-right
                                                    b Acomodações:
                                                .p-col-10
                                                    div(style='width:100%;height:1px;background-color:#a6a6a6')

                                                .p-col-2.ta-right
                                                    b Apt. IND.:
                                                .p-col-1
                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_apartamento_individual')
                                                .p-col-2.ta-right
                                                    b Apt. DUPLO:
                                                .p-col-1
                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_apartamento_duplo')
                                                .p-col-2.ta-right
                                                    b UTI:
                                                .p-col-1
                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_uti')
                                                .p-col-2.ta-right
                                                    b EXTERNO (Day Clinic):
                                                .p-col-1
                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_dia_clinica')
                                                .p-col-12.mt-2.ta-right
                                                    span 
                                                        b VALOR: {{calcValorItem(procedimento)}}
                            .p-grid.p-fluid
                                .p-col-10
                                .p-col-2.ta-right
                                    span 
                                        b VALOR TOTAL: {{formatPrice(calcValorTotal())}}

                        Panel(v-if='!transacaoId() && orcamento.ie_recebivel' header='Pagamentos').mt-4
                            .p-grid.p-fluid
                                .p-col-12
                                    BuscarPaciente(ref='buscarPaciente' label='Pagante')
                    

                        Panel.mt-2(header='Formas de pagamento' v-if='list.length && !this.transacaoId()')
                            .p-grid.p-fluid
                                .p-col-12.p-md-3(v-for='elem, idx in list' :key='idx')
                                    Card.payment-box
                                        template(slot='content')
                                            //- .ta-right(v-if='! $parent.imprimir')
                                            //-     a.btn-remove-payment(href='javascript:;' @click='openDialogRemoverPagamento(idx, elem)')
                                            //-         i.jam.jam-close
                                            .p-grid.p-fluid
                                                .p-col-6
                                                    .ta-left(v-if='elem.comprovanteAdquirente' v-tooltip.top="'Imprimir comprovante'")
                                                            a.btn-remove-payment(href='javascript:;' @click='imprimirComprovantePagamento(elem.comprovanteAdquirente)')
                                                                i.jam.jam-printer
                                                .p-col-6
                                                    .ta-right(v-tooltip.top="'Cancelar'")
                                                        a.btn-remove-payment(href='javascript:;' @click='openDialogRemoverPagamento(idx, elem)')
                                                            i.jam.jam-close
                                            .ta-center
                                                div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                                    i.pi.pi-money-bill
                                                div.icon-box(v-if='elem.cd_forma_pagamento == 5')
                                                    i.jam.jam-credit-card
                                                div.icon-box(v-if='elem.cd_forma_pagamento == 6')
                                                    i.jam.jam-id-card
                                                div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                                    i.jam.jam-envelope
                                                div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                                    i.jam.jam-phone
                                                p.text-method {{ elem.cd_forma_pagamento_f }}
                                                p.text-value {{ elem.nr_valor_f }}
                                                p.text-parcelas(v-if='elem.cd_forma_pagamento == 5') Total: {{ formatPrice(elem.valor_total) }}

                       


        .ta-right.mt-4
            div(v-if='orcamento.cd_orcamento_situacao.ds_sigla == "PP" && !this.transacaoId()')
                span.info-resta(v-if='totalPago() > 0 && totalPago() < calcValorTotal()') (Resta {{ formatPrice(calcValorTotal() - totalPago()) }})
                Button(
                    v-if='totalPago() < calcValorTotal()'
                    label='Forma de pagamento'
                    icon="jam jam-plus"
                    @click='nr_valor_adicionar = 0; dialogPagamento = true'
                    )
                div(v-else-if='$refs.buscarPaciente.model && !transacaoId()')
                    Button.btn-finalizar.p-button-success(
                        label='Finalizar'
                        icon="jam jam-check"
                        @click='dialogFinalizar = true'
                        )
            div(v-if='transacaoId()')
                Imprimir(ref='boxImprimir' :id='transacaoId()' :guias='guias' @setGuias='setGuias')
                .p-col-12
                    Button.btn-finalizar.p-button-success.mr-2(
                        label='Ir para transação'
                        icon='jam jam-arrow-up'
                        type='button'
                        @click='$router.push(`/transacoes-financeiras-operacional/visualizar/${transacaoId()}/`)'
                        )
                    Button.btn-finalizar.mr-2(
                        label='Imprimir guias' 
                        icon='jam jam-files'
                        @click='imprimirTodasGuias()')
                    .mr-4(v-if='waitingImprimirTermica' style='display:inline-block;vertical-align:middle')
                        ProgressSpinner(strokeWidth='4')
                    Button.btn-finalizar.p-button-warning.mr-2(
                        v-else
                        label='Imprimir térmica'
                        icon="jam jam-newspaper"
                        type='button'
                        @click='imprimirTermica()'
                        )
                    Button.btn-finalizar(
                        label='Imprimir comprovante'
                        icon="jam jam-printer"
                        type='button'
                        @click='imprimirComprovante()'
                        )
                            
                
</template>

<style lang="scss">
    .visualizar-orcamento{
        .label-qtd{
            display:inline-block;
            padding:5px;
            background:#EEE;
            border-radius:5px;
            border:1px solid #CCC;
        }
        .waitingImprimirTermica {
            width: 25px;
            height: auto;
        }
        .p-progress-spinner {
            height: 32px;
        }
        .box-imprimir {
            .p-progress-spinner {
                height: 29px;
                width: 100%;
            }
        }
        .box-imprimir-todas {
            .p-progress-spinner {
                height: 34px;
                width: 34px;
            }
        }
        .box-total {
            border: 1px solid #c8c8c8;
            border-top: none;
            text-align: right;
            padding: 0.857em;
            background-color: #f4f4f4;
            .span-total {
                width: 13.5%;
                margin-left: 1%;
                display: inline-block;
                text-align: center;
            }
            p {
                margin: 0;
            }
        }
        .dialog-opcoes-impressao {
            width: 600px;
            max-width: 96%;
        }
        .dialogPagamento {
            width: 96%;
            max-width: 500px;
            .p-inputnumber-input {
                text-align: right;
            }
            .p-dialog-content {
                overflow: visible !important;
            }
            .input-adicionar p-inputnumber-input p-inputtext p-component p-filled  p-component:disabled{
            opacity: 1;
            }
        }
        .dialogFinalizar {
            width: 96%;
            max-width: 400px;
            h1 {
                font-size: 16px;
            }
            p {
                margin: 2px 0;
            }
        }
        .p-panel-titlebar {
            .title-header-panel {
                span {
                    margin-left: 10px;
                }
                width: 50%;
                display: flex;
                align-items: center;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;     
        }
       .payment-box {
			min-height:280px;
			.text-method {
				font-size: 18px;
				margin: 0;
				margin-top: 14px;
			}
			.text-value {
				font-size: 22px;
				font-weight: 700;
				margin: 0;
				margin-top: 10px;
			}
			.text-parcelas{
				font-size: 15px;
				margin-top: 14px;
			}
			.icon-box {
				width: 100px;
				height: 100px;
				line-height: 130px;
				border-radius: 50%;
				display: inline-block;
				background-color: #312c66;
				i { font-size: 44px; color: #fff; }
			}
			.btn-remove-payment {
				display: inline-block;
				background-color: #c8c8c8;
				width: 20px;
				height: 20px;
				text-align: center;
				line-height: 16px;
				border-radius: 50%;
				i { font-size: 20px; color: #666; }
			}
		}
        .info-resta {
            font-size: 20px;
            margin-right: 20px;
            font-weight: 700;
            color: #888;
        }
        .btn-finalizar {
            font-size: 16px !important;
        }
        .__box-imprimir {
            visibility: hidden;
        }
        .imprimir-transacao {
            @media screen {
                display: none;
            }
        }
        .input-adicionar p-inputnumber-input p-inputtext p-component p-filled {
                opacity: 1 !important;
        }
        .parcelas{
            .p-dropdown-item{
                display: inline-grid;
                width: 100%;
                word-wrap: break-word;
                white-space: initial;
                .p-highlight{
                    color: white !important;
                }
            }
        }
        .span-input {
            width: 100% !important;
        }
    }
</style>

<script>
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import Dropdown from "primevue/dropdown";
    import Card from "primevue/card";
    import InputSwitch from "primevue/inputswitch";
    import InputText from "primevue/inputtext";
    import Panel from "primevue/panel";
    import Textarea from "primevue/textarea";
    import BuscarPaciente from './../Paciente/BuscarPaciente';
    import ProgressSpinner from 'primevue/progressspinner';
    import Tooltip from 'primevue/tooltip';
    import { Caixas,  Orcamento, Especialista, TransacaoCartao } from '../../middleware';
    import { pCalendarLocale_ptbr } from "./../../utils";
    import Imprimir from './../TransacoesFinanceiras/Imprimir';
    import InputNumber from 'primevue/inputnumber'
    import CurrencyInput from "./../CustomComponents/CurrencyInput";
    import ConfirmDialog from "@/components/CustomComponents/ConfirmDialog";

    import Message from 'primevue/message';


    export default {
        props: {
            orcamento: { type: Object, required: true }
        },
        components: { Card, InputSwitch, InputText, Panel, Textarea, BuscarPaciente, Button, Message,
        Dropdown, Dialog, Imprimir, Tooltip, ProgressSpinner, InputNumber, CurrencyInput, ConfirmDialog },
        directives: { tooltip: Tooltip },
        watch: {
            'orcamento.id': function() {
                TransacaoCartao.findAll({cd_orcamento: this.orcamento.id, ie_situacao: 'PA'}).then(response => {
                    response.data.forEach(item => {
                        this.list.push({
                        cd_forma_pagamento: this.options.formasPagamento.find(elem => elem.text == item.payment_method)?.value,
                        cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.text == item.payment_method)?.text,
                        nr_valor: Number(item.nr_valor),
                        nr_valor_f: this.formatPrice(item.nr_valor),
                        nr_credito_parcelas: item.installments,
                        valor_total : item.amount,
                        cd_transacao_cartao: item.id
                        })
                    })
                })
            },
            "modelManual.cd_operadora": function(valor){
                this.modelManual.ie_bandeira = null
                this.ie_cobrar_cliente = this.options.operadoras.find(item => item.id === valor)?.ie_cobrar_cliente
                if(!this.options.operadoras.find(item => item.id === valor)?.ie_cobrar_cliente) {
                    this.options.parcelasCredishop.forEach(item => {
                            item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * 1 / +item.nr_parcela)}`
                            item.value = +item.nr_parcela
                            item.valor_total = this.model.nr_valor_adicionar * 1
                    })
                    this.options.parcelasCredishop = this.options.parcelasCredishop.filter(i => i.value <= 6)
                } else {
                    this.options.parcelas.forEach(item => {
                        item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * +item.nr_juros / +item.nr_parcela)}`
                        item.value = +item.nr_parcela
                        item.valor_total = this.model.nr_valor_adicionar * +item.nr_juros
                    })
                }
                if(this.model.nr_credito_parcelas != null)
                    this.$toast.info('As taxas mudaram, selecione as parcelas novamente', { duration: 6000 })
                this.model.nr_credito_parcelas = null
            },
            "model.cd_forma_pagamento": function(value) {
                if(value) {
                    Caixas.getBandeirasOperadoras({cd_forma_pagamento: value}).then(response => {
                        this.options.operadoras = response.data
                        response.data.forEach(item => {
                            this.options.bandeiras[item.id] = item.cd_bandeiras
                    })
            })
                }
                
            },
            "model.nr_valor_adicionar": function(valor){
                if(valor == 0)
                    this.model.nr_credito_parcelas = null;
                else {
                    this.options.parcelasCredishop.forEach(item => {
                        item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * 1 / +item.nr_parcela)}`
                        item.value = +item.nr_parcela
                        item.valor_total = this.model.nr_valor_adicionar * 1
                    })
                    this.options.parcelasCredishop = this.options.parcelasCredishop.filter(i => i.value <= 6)

                    this.options.parcelas.forEach(item => {
                        item.text = `${item.nr_parcela} X ${this.formatPrice(this.model.nr_valor_adicionar * +item.nr_juros / +item.nr_parcela)}`
                        item.value = +item.nr_parcela
                        item.valor_total = this.model.nr_valor_adicionar * +item.nr_juros
                    })
                }
            },
            dialogPagamento: function() {
                setTimeout(() => { let el = document.querySelector('.input-adicionar');
                    if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
                    { this.model.nr_valor_adicionar = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
                    el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }
                })
            }
        },

        computed: {
            troco() {
                return this.model.valor_pago && this.valor_a_adicionar ? +this.model.valor_pago - +this.valor_a_adicionar : 0
            },
            valor_a_adicionar:{
                get() { 
                    const resto = this.calcValorTotal() - this.totalPago();
                    if(this.model.valor_pago > resto && this.model.nr_valor_adicionar == 0) return resto;
                    return this.model.nr_valor_adicionar;
                },
                set(val){ this.model.nr_valor_adicionar = val; }
            },
            valor_juros() {
                if(this.model.nr_credito_parcelas) {
                    if(this.ie_cobrar_cliente)
                        return +this.model.nr_valor_adicionar * +this.options.parcelas.find(item => item.value == +this.model.nr_credito_parcelas).nr_juros
                    else {
                        return +this.model.nr_valor_adicionar * 1
                    }           
                }
                return 0;
            }
        },
        mounted() {
            if(!this.transacaoId())
                if(this.orcamento.cd_paciente?.nr_cpf){
                    this.$refs.buscarPaciente.filtro.selected.value = this.orcamento.cd_paciente?.nr_cpf
                    this.$refs.buscarPaciente.buscar()
                }
        },
        created() {
            Especialista.findByEstabelecimento(
                    this.orcamento.cd_estabelecimento.id
                ).then(response => {
                    this.waitingEspecialista = false;
                    if (response.status === 200) this.especialistas = response.data;
                });
             
            Caixas.getFormasPagamento().then(response => {
                this.waiting = false
                if (response.status == 200) {
                    if (response.data.length) {
                        response.data.forEach(elem => {
                            this.options.formasPagamento.push({ value: elem.id, text: elem.nm_forma_pagamento })
                        })
                        this.cd_forma_pagamento = ''
                    }
                }
            }).then(() => {
                TransacaoCartao.findAll({cd_orcamento_cirurgia: this.orcamento.id, ie_situacao: 'PA'}).then(response => {
                    response.data.forEach(item => {
                        this.list.push({
                        cd_forma_pagamento: item.payment_method == 'pix' ? 3 : this.options.formasPagamento.find(elem => elem.text == item.payment_method)?.value,
                        cd_forma_pagamento_f:item.payment_method == 'pix'? "Pix / Transferência" :  this.options.formasPagamento.find(elem => elem.text == item.payment_method)?.text,
                        nr_valor: Number(item.nr_valor),
                        nr_valor_f: this.formatPrice(item.nr_valor),
                        nr_credito_parcelas: item.installments,
                        valor_total : item.amount,
                        cd_transacao_cartao: item.id,
                        comprovanteAdquirente: item.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente

                    })
                        })
                })
            })
			
            Caixas.getjuros().then(response => {
                if (response.status == 200) {
                    this.options.parcelas = Object.assign([], response.data); 
                    this.options.parcelasCredishop = Object.assign([], response.data); 
                }
            })


        },
        
        data() {
            return {
                list: [],
                ptbr: pCalendarLocale_ptbr,
                transacaoId() {
                return this.orcamento.cd_transacao_financeira && ((['PA', 'AU']).includes(this.orcamento.cd_orcamento_situacao.ds_sigla))?
                    this.orcamento.cd_transacao_financeira 
                    : this.novaTransacao;
                },
                model: {
                    cd_forma_pagamento: null,
                    nr_valor_adicionar: 0,
                    valor_pago: 0,
                    nr_credito_parcelas: null,
                },
                modelManual: {
                    cd_pagante: null,
                    cd_orcamento: null,
                    cd_forma_pagamento: null,
                    nr_valor: 0,
                    qt_parcelas: 1,
                    ie_registro_manual: false,
                    cd_operadora: null,
                    ie_bandeira: null,
                    nr_final_cartao: null,
                    nr_inicio_cartao: null,
                    cd_autorizacao: null,
                    nr_pix_doc: null
                },
               options: {
                    formasPagamento: [],
                    parcelas: [],
                    parcelasCredishop: [],
                    bandeiras: {},
                    operadoras: []
                },
                dialogRemoverPagamento:{
					visible: false,
					idx: null,
					elem: null,
				},
                novaTransacao: null,
                nr_valor_adicionar: 0,
                cd_forma_pagamento: null,
                valor_pago: 0,
                nr_credito_parcelas: null,
                digitos: null,
                codigo: null,
                bandeiras: '',
                dialogPagamento: false,
                dialogFinalizar: false,
                dialogOpcoesImpressao: {
                    visible: false,
                    cd_horario_agendamento: null,
                    waiting: false,
                },
                waitingImprimirTermica: false,
                comprovantePagamentoImprimir: null,
                waitingPagar: false,
                nm_pessoa_fisica: null,
                waitingFinalizar: false,
                especialistas: [],
                ie_cobrar_cliente : false,
                waitingPagamentoAprovado: false,
                waitingCancelamento: false,
                guias: 0,
                totalPago () {
                    let sum = 0                 
                    this.list.forEach(elem => sum += elem.nr_valor)
                    sum = sum.toFixed(2)
                    return parseFloat(sum)
                },
                calcValorTotal() {
                    let sum = this.orcamento.procedimentos.reduce((acc, item) => {
                        return (    
                            +item.nr_material_cirurgico +
                            +item.nr_valor_anestesista +
                            +item.nr_valor_exames +
                            +item.nr_valor_fisioterapeuta +
                            +item.nr_valor_hemoderivados +
                            +item.nr_valor_honorario +
                            +item.nr_valor_hospital +
                            +item.nr_valor_opme +
                            +item.nr_valor_perfusao +
                            +item.nr_valor_repasse +
                            +item.nr_valor_taxa
                        );
                    }, 0)
                    return parseFloat(sum.toFixed(2));
                },
            };
        },
        methods: {
        
            formatPrice (val) {
                    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
                },
            calcValorItem(item) {
                return this.$root.formatPrice(
                    +item.nr_material_cirurgico +
                        +item.nr_valor_anestesista +
                        +item.nr_valor_exames +
                        +item.nr_valor_fisioterapeuta +
                        +item.nr_valor_hemoderivados +
                        +item.nr_valor_honorario +
                        +item.nr_valor_hospital +
                        +item.nr_valor_opme +
                        +item.nr_valor_perfusao +
                        +item.nr_valor_repasse +
                        +item.nr_valor_taxa
                );
            },
            imprimirTodasGuias () {
                this.dialogOpcoesImpressao.visible =  true
				// this.$refs.boxImprimir.openDialogImpressao()
			},
            imprimirComprovante () {
                this.$refs.boxImprimir.imprimirComprovante()
            },
            setGuias(val) {
				this.guias = val
			},
            imprimirTermica() {
                this.waitingImprimirTermica = true
                Caixas.findTransacaoPDF(this.transacaoId()).then(response => {
                    this.waitingImprimirTermica = false
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'text/html; charset=utf-8' })
                            var fd = new FormData();
                            fd.append('file', blob);
                            fetch('http://localhost:9990/imprimir', {
                                method: 'POST',
                                body: fd
                            })

                            //let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            //var fileURL = URL.createObjectURL(blob);
                            //let a = window.open(fileURL);
                            //a.print()
                            //saveAs(blob, `comprovante-transacao-${ this.transacaoId }.pdf`)
                        }
                    }
                })
            },
            manualTEF(args) {
                // console.log(this.modelManual, args)
                this.modelManual['ie_registro_manual'] = !args.value
            },
            selecionarPagante (data) {
                this.$refs.buscarPaciente.limpar()
                if( data.cd_paciente != undefined && data.cd_paciente.cd_pai ){
                    this.$refs.buscarPaciente.filtro.selected.value = data.cd_paciente.cd_pai.nr_cpf_f
                } else {
                    this.$refs.buscarPaciente.filtro.selected.value = data.nr_cpf_f
                }

                this.$refs.buscarPaciente.buscar()
            },
            async adicionar () {
                if (this.model.nr_valor_adicionar <= 0) {
                    this.$toast.error('Valor deve ser superior a R$ 0,00', { duration: 3000 })
                    return
                } else if (this.model.nr_valor_adicionar > (this.calcValorTotal() - this.totalPago()).toFixed(2)) {
                    this.$toast.error('Valor digitado maior que o valor restante', { duration: 3000 })
                    return
                } else if (this.model.cd_forma_pagamento == '' || this.model.cd_forma_pagamento == null) {
                    this.$toast.error('Selecione a Forma de Pagamento', { duration: 3000 })
                    return
                } else if (!this.$refs.buscarPaciente.cd_pessoa_fisica) {
                    this.$toast.error('Selecione o Pagante', { duration: 3000 })
                    return
                }

                if(this.model.cd_forma_pagamento == 1 || this.model.cd_forma_pagamento == 4) {
					this.list.push({
						cd_forma_pagamento: this.model.cd_forma_pagamento,
						cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.value == this.model.cd_forma_pagamento).text,
						nr_valor: this.model.nr_valor_adicionar,
						nr_valor_f: this.formatPrice(this.model.nr_valor_adicionar),
						nr_credito_parcelas: this.model.nr_credito_parcelas,
						valor_total : this.model.cd_forma_pagamento == 5? this.valor_juros : null
					})
					this.dialogPagamento = false
					this.$toast.success('Forma de pagamento adicionada', { duration: 3000 })
					return
				}

                this.modelManual.cd_pagante = this.$refs.buscarPaciente.cd_pessoa_fisica
                this.modelManual.cd_orcamento_cirurgia = this.orcamento.id
                // this.modelManual.cd_orcamento = this.orcamento.id
                this.modelManual.cd_forma_pagamento = this.model.cd_forma_pagamento
                this.modelManual.nr_valor = this.valor_a_adicionar
                this.modelManual.qt_parcelas = this.model.nr_credito_parcelas ? this.model.nr_credito_parcelas : 1
                this.modelManual.cd_autorizacao = this.modelManual.cd_autorizacao ? this.modelManual.cd_autorizacao : ''
                this.modelManual.nr_pix_doc = this.modelManual.nr_pix_doc ? this.modelManual.nr_pix_doc : ''

                if(this.modelManual.ie_registro_manual) {
                    if([5, 6].includes(this.model.cd_forma_pagamento)) {
                        this.modelManual.nr_final_cartao = (this.modelManual.nr_final_cartao || "").replace(/\D/g, '')
                        if (this.modelManual.nr_final_cartao.length !== 4) {
                            this.$toast.error('Informe os 4 últimos dígitos do cartão', { duration: 3000 })
                            return
                        }
                    }
					this.modelManual.cd_autorizacao = this.modelManual.cd_autorizacao || ''
					this.modelManual.nr_pix_doc = this.modelManual.nr_pix_doc || ''
				}
                this.waitingPagamentoAprovado = true;
                try{
                    let response = await Caixas.pagamentoTEF(this.modelManual)
                    if(![200, 201].includes(response.status)) {
						throw new Error(response.data.detail)
					}

                    const vendaAprovada = await this.loopConsultarVenda(response.data.id)
                    if(vendaAprovada){
                        this.list.push({
                            cd_forma_pagamento: this.model.cd_forma_pagamento,
                            cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.value == this.model.cd_forma_pagamento).text,
                            nr_valor: this.model.nr_valor_adicionar,
                            nr_valor_f: this.formatPrice(this.model.nr_valor_adicionar),
                            nr_credito_parcelas: this.model.nr_credito_parcelas,
                            valor_total : this.model.cd_forma_pagamento == 5? this.options.parcelas.find(item => +item.nr_parcela == this.model.nr_credito_parcelas).valor_total : null,
                            cd_transacao_cartao: response.data.id,
                            comprovanteAdquirente: this.comprovantePagamentoImprimir
                        })
                        if(this.comprovantePagamentoImprimir) this.imprimirComprovantePagamento(this.comprovantePagamentoImprimir)
                        this.dialogPagamento = false
                        this.$toast.success('Forma de pagamento adicionada', { duration: 3000 })
                    }
                }catch (error){
                    this.waitingPagamentoAprovado = false;
                    if (error.data.non_field_errors)
                        error.data.non_field_errors.forEach(errorMsg => {
                            this.$toast.error(errorMsg, { duration: 3000 })
                        })
                    else if (error.data.detail) {
                        if (typeof error.data.detail == 'string')
                            this.$toast.error(error.data.detail, { duration: 3000 })
                        else error.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    } else if (error.data.length) {
                        error.data.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                }

                // let response = await Caixas.pagamentoTEF(this.modelManual)
                // if (([200, 201]).includes(response.status)) {
                //     this.list.push({
                //         cd_forma_pagamento: this.model.cd_forma_pagamento,
                //         cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.value == this.model.cd_forma_pagamento).text,
                //         nr_valor: this.model.nr_valor_adicionar,
                //         nr_valor_f: this.formatPrice(this.model.nr_valor_adicionar),
                //         nr_credito_parcelas: this.model.nr_credito_parcelas,
                //         valor_total : this.model.cd_forma_pagamento == 5? this.options.parcelas.find(item => +item.nr_parcela == this.model.nr_credito_parcelas).valor_total : null,
                //         cd_transacao_cartao: response.data.id
                //     })
                //     this.dialogPagamento = false
                //     this.$toast.success('Forma de pagamento adicionada', { duration: 3000 })
                // } else if (([400]).includes(response.status)) {
                //     if (response.data.non_field_errors)
                //         response.data.non_field_errors.forEach(errorMsg => {
                //             this.$toast.error(errorMsg, { duration: 3000 })
                //         })
                //     else if (response.data.detail) {
                //         if (typeof response.data.detail == 'string')
                //             this.$toast.error(response.data.detail, { duration: 3000 })
                //         else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                //     } else if (response.data.length) {
                //         response.data.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                //     }
                // }
                    
                
            },
            imprimirComprovantePagamento(comprovante){
				var comp = `<div style="white-space: pre-wrap;font-family: Open Sans, Helvetica Neue, sans-serif;
					font-stretch: condensed;
					box-sizing: border-box;
					font-size: 0.7rem;
					line-height: 0.9rem;">${comprovante}<div>`;
				
				const blob = new Blob([comp], { type: "text/html; charset=utf-8" });
				let formData = new FormData();
				formData.append("file", blob);

				fetch("http://localhost:9990/imprimir", {
				method: "POST",
				body: formData,
				})
				.then(() => {
					this.$toast.success("Comprovante impresso", { duration: 3000 })
				})
				.catch(() => {
					this.$toast.error("Sem comunicação com a impressora", {duration: 3000});
				})
			},
            async loopConsultarVenda(cd_transacao_cartao) {
				return await new Promise((resolve) => {
				//De 1 em 1s, é feita uma consulta na API do medclub para verificar o andamento da venda
				const id = setInterval(async () => {
				let venda = await Caixas.pagamentoTEFSituacao(cd_transacao_cartao);
				if(venda.status != 200) {
					this.$toast.error("Sem conexão com o servidor. Entre em contato com o administrador do sistema.", { duration: 3000 })
					resolve(false)
				}
				/*
					CÓDIGOS RESPOSTAS:
					5	Pendente
					6	EmPagamento
					10	Creditado
					15	Expirado
					18	CancelamentoIniciado
					19	EmCancelamento
					20	Cancelado
					25	PagamentoRecusado

					Mais informações: https://devpaygo.readme.io/v4/docs/apis-transacional
				*/

				// Pagamento em Andamento
				/* if (!["5", "6"].includes(venda.data.status)) {
					clearInterval(id);
				} */

				// Pagamento Creditado
				if (venda.data.status == "10") {
                    this.comprovantePagamentoImprimir = venda.data.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente
					this.waitingPagamentoAprovado = false
					this.cd_transacao_cartao = cd_transacao_cartao;
					clearInterval(id)
					resolve(true);
				}

				// Pagamento Cancelado ou Recusado
				if (["15", "18", "19", "20", "25"].includes(venda.data.status)) {
					this.waitingPagamentoAprovado = false
					this.$toast.error('O pagamento foi cancelado ou recusado', { duration: 5000 })
					clearInterval(id)
					resolve(false);
				}
				}, 1500);
				})
			},
			async loopConsultarVendaCancelada(cd_transacao_cartao) {
				return await new Promise((resolve) => {
				//De 1 em 1s, é feita uma consulta na API do medclub para verificar o andamento da venda
				const id = setInterval(async () => {
				let venda = await Caixas.pagamentoTEFSituacao(cd_transacao_cartao);
				/*
					CÓDIGOS RESPOSTAS:
					5	Pendente
					6	EmPagamento
					10	Creditado
					15	Expirado
					18	CancelamentoIniciado
					19	EmCancelamento
					20	Cancelado
					25	PagamentoRecusado

					Mais informações: https://devpaygo.readme.io/v4/docs/apis-transacional
				*/

				// Pagamento em Andamento
				/* if (!["5", "6"].includes(venda.data.status)) {
					clearInterval(id);
				} */

				// Pagamento Creditado
				if (venda.data.status == "20") {
                    this.comprovantePagamentoImprimir = venda.data.object_pagarme?.intencoesVendas[0]?.pagamentosExternos[0]?.comprovanteAdquirente
					this.waitingPagamentoAprovado = false
					this.cd_transacao_cartao = cd_transacao_cartao;
					clearInterval(id)
					resolve(true);
				}

				// Pagamento Cancelado ou Recusado
				if (["15", "18", "10", "25"].includes(venda.data.status)) {
					this.waitingPagamentoAprovado = false
					this.$toast.error('Não foi possivel realizar o cancelamento', { duration: 5000 })
					clearInterval(id)
					resolve(false);
				}
				}, 1500);
				})
			},
            onSubmit() {
                this.waitingPagar = true
                Orcamento.validar({
                    cd_especialista: this.orcamento.cd_orcamento_cirurgia_procedimento_estabelecimento.cd_especialista? undefined : this.especialistas[0].id,
                    id: this.orcamento.id,
                    movimento_caixa: this.list.map(item => ({
                        nm_movimento_caixa: 'Pagamento cliente',
                        nr_valor: item.nr_valor, 
                        cd_forma_pagamento: item.cd_forma_pagamento,
                        nr_credito_parcelas: item.cd_forma_pagamento == 5? item.nr_credito_parcelas : 1,
                        cd_transacao_cartao: item.cd_transacao_cartao || null
                    })),
                    cd_pagante: this.$refs.buscarPaciente.model.id,
                    nr_valor: this.calcValorTotal() 
                }).then(response => {
                    this.waitingPagar = false;
                    this.dialogFinalizar = false;
                    if(([200,201]).includes(response.status)){
                        this.novaTransacao = response.data.cd_transacao;
                    }else if (([400]).includes(response.status)) {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        } else if (response.data.length) {
                            response.data.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                    }
                })
            },
            imprimirGuias (termica=false) {
                this.$refs.boxImprimir.imprimirGuias(termica)
                this.dialogOpcoesImpressao.visible = false
            },
            openDialogRemoverPagamento(idx, elem) {
				this.dialogRemoverPagamento.idx = idx
				this.dialogRemoverPagamento.elem = elem
				this.dialogRemoverPagamento.visible = true
			},
            async removeFormadePagamento(idx, elem) {
                this.waitingCancelamento = true;
				if(elem.cd_forma_pagamento == 1 || elem.cd_forma_pagamento == 4) {
					this.list.splice(idx, 1)
					this.dialogRemoverPagamento.visible = false
					this.$toast.success('Forma de pagamento removida', { duration: 3000 })
                    this.waitingCancelamento = false;
				} else {
                    if(!elem.ie_registro_manual) {
						try {
							await Caixas.removePagamentoTEF(elem.cd_transacao_cartao)
							const cancelado = await this.loopConsultarVendaCancelada(elem.cd_transacao_cartao)
							if(cancelado) {
								this.list.splice(idx, 1)
								this.$toast.success('Forma de pagamento removida', { duration: 3000 })
							}
                            if(this.comprovantePagamentoImprimir) this.imprimirComprovantePagamento(this.comprovantePagamentoImprimir)
							this.waitingCancelamento = false
							this.dialogRemoverPagamento.visible = false
						} catch (error) {
							this.$toast.error(error, { duration: 3000 })
						}
					}else{
                        try{
                            let response =  await Caixas.removePagamentoTEF(elem.cd_transacao_cartao)
                            if (([200, 201]).includes(response.status)) {
                                this.list.splice(idx, 1)
                                this.$toast.success('Forma de pagamento removida', { duration: 3000 })
                                this.waitingCancelamento = false;
                            }
                        }catch(error) {
                            this.waitingCancelamento = false;
                            if (error.data.non_field_errors)
                                error.data.non_field_errors.forEach(errorMsg => {
                                    this.$toast.error(errorMsg, { duration: 3000 })
                                })
                            else if (error.data.detail) {
                                if (typeof error.data.detail == 'string')
                                    this.$toast.error(error.data.detail, { duration: 3000 })
                                else error.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            } else if (error.data.length) {
                                error.data.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            }
                        }
                    }
                    // let response =  await Caixas.removePagamentoTEF(elem.cd_transacao_cartao)
                    // if (([200, 201]).includes(response.status)) {
                    //     this.list.splice(idx, 1)
                    //     this.$toast.success('Forma de pagamento removida', { duration: 3000 })
                    // } else if (([400]).includes(response.status)) {
                    //     if (response.data.non_field_errors)
                    //         response.data.non_field_errors.forEach(errorMsg => {
                    //             this.$toast.error(errorMsg, { duration: 3000 })
                    //         })
                    //     else if (response.data.detail) {
                    //         if (typeof response.data.detail == 'string')
                    //             this.$toast.error(response.data.detail, { duration: 3000 })
                    //         else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    //     } else if (response.data.length) {
                    //         response.data.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    //     }
					// }
					this.dialogRemoverPagamento.visible = false
				}
			},
            onHide() {
                this.valor_a_adicionar = 0;
				this.modelManual = {
					cd_pagante: null,
					cd_forma_pagamento: null,
					nr_valor: 0,
					qt_parcelas: 1,
					ie_registro_manual: true,
					cd_operadora: null,
					ie_bandeira: null,
					nr_final_cartao: null,
					nr_inicio_cartao: null,
					cd_autorizacao: null,
					nr_pix_doc: null
				}
			}
        }   
    };
</script>

<style scoped></style>

